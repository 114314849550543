<template>
  <div>
    <div class="d-flex flex-row mb-3">
      <button
        :disabled="IsLoading"
        type="button"
        class="btn btn-white btn-sm text-nowrap"
        @click="fetchMandants"
      >Reload</button>
      <button
        :disabled="IsLoading"
        type="button"
        class="btn btn-primary btn-sm ml-2 text-nowrap"
        @click="createMandant"
      >Create new mandant</button>

      <div>
        <b-form-select
          v-model="sortBy"
          :options="sortOptions"
          size="sm"
          class="sort-select select-amz d-inline-block ml-2"
          @change="filterMandants"
        ></b-form-select>
      </div>
    </div>

    <b-form-input
      v-model="searchText"
      class="search-text"
      type="text"
      debounce="150"
      placeholder="Search Code, Title, Keyword"
      autofocus
      autocomplete="off"
      @update="filterMandants"
    ></b-form-input>

    <table class="table table-google table-mandants mt-2">
      <thead>
        <tr>
          <th class="text-right">Code</th>
          <th>Title</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="mandant in filteredMandants" :key="mandant.code">
          <!-- code -->
          <td class="row-fit text-right">{{ mandant.code }}</td>
          <!-- title -->
          <td>
            <b-form-input
              v-if="mandant.editMode"
              v-model="mandant.title"
              size="sm"
              placeholder="Title"
              @keyup.enter="saveMandantChanges(mandant)"
              :disabled="mandant.loading"
            ></b-form-input>
            <span v-else>
              {{ mandant.title }}
            </span>
          </td>
          <!-- action -->
          <td class="text-nowrap row-fit">
            <div v-if="mandant.editMode" class="d-flex justify-content-end">
              <button
                type="button"
                class="btn btn-primary btn-sm mr-1"
                :disabled="mandant.loading"
                @click="saveMandantChanges(mandant)"
                title="Save changes"
              >
                Save
              </button>
              <button
                type="button"
                class="btn btn-white btn-sm"
                :disabled="mandant.loading"
                @click="cancelMandantChanges(mandant)"
                title="Cancel changes"
              >
                Cancel
              </button>
            </div>
            <div v-else class="d-flex justify-content-end">
              <button
                v-if="mandant.token"
                type="button"
                class="btn btn-white btn-sm mr-1"
                @click="mandant.showToken = !mandant.showToken"
                :title="mandant.showToken ? 'Hide token' : 'Show token'"
              >
                <span v-if="mandant.showToken">Hide token</span>
                <span v-else>Show token</span>
              </button>
              <button
                type="button"
                class="btn btn-primary btn-sm mr-1"
                :disabled="mandant.loading"
                @click="mandant.editMode = true"
                title="Edit mandant"
              >
                Edit
              </button>
              <button
                type="button"
                class="btn btn-danger btn-sm"
                :disabled="mandant.loading"
                @click="deleteMandant(mandant)"
                title="Delete mandant"
              >
                Delete
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
const _ = require('lodash');

export default {
  name: 'AdminMandants',
  components: {},
  computed: {
    IsLoading() {
      return this.loadingCount > 0 || this.mandants.some((mandant) => mandant.loading);
    },
  },
  data() {
    return {
      loadingCount: 0,
      searchText: '',
      mandants: [],
      filteredMandants: [],
      sortBy: 'code',
      sortOptions: [
        { value: 'code', text: 'Sort by: Code' },
        { value: 'title', text: 'Sort by: Title' },
      ],
    };
  },
  methods: {
    setupMandantMeta(mandant) {
      mandant.showToken = false;
      mandant.editMode = false;
      mandant.loading = false;
    },
    filterMandants() {
      this.filteredMandants = this.mandants.filter((mandant) => (
        mandant.editMode
          || mandant.code === this.searchText
          || mandant.title.toLowerCase().includes(this.searchText.toLowerCase())
      ));
      this.filteredMandants = _.sortBy(this.filteredMandants, this.sortBy);
    },
    fetchMandants() {
      if (this.IsLoading) return;
      this.loadingCount += 1;
      this.$http
        .get('/mandants')
        .then((res) => {
          res.body.mandants.forEach((mandant) => {
            this.setupMandantMeta(mandant);
          });
          this.mandants = res.body.mandants;
          this.filterMandants();
        })
        .catch((err) => {
          alert(`Failed to fetch mandants: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount -= 1;
        });
    },
    createMandant() {
      if (this.IsLoading) return;
      const mandantCode = prompt('Enter mandant code');
      if (mandantCode === null || mandantCode === '') return;
      this.loadingCount += 1;
      this.$http
        .post('/mandants')
        .send({ mandantCode })
        .then((res) => {
          this.setupMandantMeta(res.body.mandant);
          this.mandants.push(res.body.mandant);
          this.filterMandants();
        })
        .catch((err) => {
          alert(`Failed to create new mandant: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount -= 1;
        });
    },
    deleteMandant(mandant) {
      if (mandant.loading) return;
      const confirmed = confirm(`Do you really wish to delete mandant '${mandant.code}'?`);
      if (!confirmed) return;

      mandant.loading = true;
      this.$http
        .delete(`/mandants/${mandant.code}`)
        .then(() => {
          this.mandants = this.mandants.filter((_mandant) => _mandant.code !== mandant.code);
          this.filterMandants();
        })
        .catch((err) => {
          alert(`Failed to delete mandant: ${err.response.text}`);
        })
        .finally(() => {
          mandant.loading = false;
        });
    },
    saveMandantChanges(mandant) {
      if (mandant.loading) return;

      mandant.loading = true;
      const mandantChanges = {
        title: mandant.title,
        token: mandant.token,
      };
      this.$http
        .put(`/mandants/${mandant.code}`)
        .send({ mandantChanges })
        .then((res) => {
          this.setupMandantMeta(res.body.mandant);
          // just to verify that changes were made
          Object.keys(mandant).forEach((key) => {
            mandant[key] = res.body.mandant[key];
          });
          mandant.editMode = false;
          this.filterMandants();
        })
        .catch((err) => {
          alert(`Failed to save changes: ${err.response.text}`);
        })
        .finally(() => {
          mandant.loading = false;
        });
    },
    cancelMandantChanges(mandant) {
      if (mandant.loading) return;

      mandant.loading = true;
      this.$http
        .get(`/mandants/${mandant.code}`)
        .then((res) => {
          this.setupMandantMeta(res.body.mandant);
          Object.keys(mandant).forEach((key) => {
            mandant[key] = res.body.mandant[key];
          });
          this.filterMandants();
        })
        .catch((err) => {
          alert(`Failed to show live values: ${err.response.text}`);
        })
        .finally(() => {
          mandant.editMode = false;
          mandant.loading = false;
        });
    },
  },
  created() {
    this.fetchMandants();
  },
};
</script>

<style lang="scss" scoped>
.search-text,
.table-mandants {
  max-width: 1000px;
}
.sort-select {
  width: 150px;
}
</style>
